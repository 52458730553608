import React from "react"
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
import MarkDown from "react-markdown";

// components
import Layout from "../components/layout";
import Seo from "../components/seo";
import AwardSlider from "../components/award-slider"
import LiveChat from "../components/Home/livechat";
import Counter from "../components/counter";


const Award = (props) => {
  const info = props.data.awardData
  const SEOdata = props.data.awardpageData.edges[0].node.frontmatter?.seo_metadata
  const Schemadata = props.data.awardpageData.edges[0].node.frontmatter?.Schema
  const Hreflang = props.data.awardpageData.edges[0].node.frontmatter?.Hreflang

  const achievement = props.data.achievement
  return (
    <>
      <Layout>
        <Seo
          title={SEOdata ? SEOdata.seo_title : "Awards"}
          description={SEOdata ? SEOdata.seo_description : ""}
          keywords={SEOdata ? SEOdata.seo_keyword : ""}
          image={SEOdata ? SEOdata.seo_Image?.childImageSharp?.gatsbyImageData?.images?.fallback?.src : ''}
          single_image={SEOdata ? SEOdata.seo_Single_Image : ''}
          currentPageUrlPath={props.location.pathname}
          image_format={SEOdata ? SEOdata.image_format : ""}
          image_height={SEOdata ? SEOdata.feature_image_height : ""}
          img_width={SEOdata ? SEOdata.img_width : ""}
          facebook_url={SEOdata ? SEOdata.facebook_url : ""}
          twitter_page_username={SEOdata ? SEOdata.twitter_page_username : ""}
          twitter_image_alt_tag={SEOdata ? SEOdata.twitter_image_alt_tag : ""}
          twitter_estimated_reading_lable={SEOdata ? SEOdata.twitter_estimated_reading_lable : ""}
          twitter_estimated_reading_time={SEOdata ? SEOdata.twitter_estimated_reading_time : ""}
          page_publish_date={SEOdata ? SEOdata.page_publish_date : ""}
          page_last_modified_time={SEOdata ? SEOdata.page_last_modified_time : ""}
          sitelinks_schema={Schemadata ? Schemadata.sitelinks_schema : ""}
          breadcrumb_schema={Schemadata ? Schemadata.breadcrumb_schema : ""}
          logo_schema={Schemadata ? Schemadata.logo_schema : ""}
          organization_schema={Schemadata ? Schemadata.organization_schema : ""}
          aggregate_rating_schema={Schemadata ? Schemadata.aggregate_rating_schema : ""}
          local_business_schema={Schemadata ? Schemadata.local_business_schema : ""}
          site_navigation_schema={Schemadata ? Schemadata.site_navigation_schema : ""}
          speakable_specification_schema={Schemadata ? Schemadata.speakable_specification_schema : ""}
          hreflang1={Hreflang ? Hreflang.hreflang_1 : ""}
          hreflang2={Hreflang ? Hreflang.hreflang_2 : ""}
          hreflang3={Hreflang ? Hreflang.hreflang_3 : ""}
          hreflang4={Hreflang ? Hreflang.hreflang_4 : ""}
        />

        {/* banner section */}
        <section className="mt-60 md:mt-67 lg:mt-20 lg:pb-54 overflow-hidden">
          <BgImage image={getImage(info.frontmatter.banner.image)} className="award-section-bg" alt={`DecodeUp Award Banner`}>
            <div className="h-full">
              <div className="container mx-auto h-full">
                <div className=" xl:px-1-10  text-center font-poppings-bold flex flex-col items-center justify-center h-full">
                  <p className="mb-1-1 lg:mb-2 font-poppings-bold uppercase text-rh6 lg:text-h6 text-cyan">
                    {info.frontmatter.sub_title}
                  </p>
                  <MarkDown className="text-white text-rh2 sm:text-h3 lg:text-h2" children={info.frontmatter.banner.content} allowDangerousHtml />
                </div>
              </div>
            </div>
          </BgImage>
        </section>

        {/* Awards slider section */}
        <section className="my-10 lg:mt-58 lg:mb-10">
          <div className="mx-auto container">
            <div className="pb-35 lg:pb-50 mx-auto w-full xl:w-2/3 text-center">
              <h3 className="mb-1 lg:mb-0 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
                {info.frontmatter.awards_sec.title}
              </h3>
              <MarkDown className="px-4 sm:px-0 text-p4 lg:text-p2 font-worksans-normal text-term-primary testi-text" children={info.frontmatter.awards_sec.description} allowDangerousHtml />
            </div>
            {/* award slider  */}
            <div>
              <AwardSlider awardSlides={info.frontmatter.awards_sec.award_slides.slides} />
            </div>
          </div>
        </section>

        {/* Award winner section */}
        <section className="py-50 lg:pt-1-27 lg:pb-1-24 bg-light-grey">
          <div className="mx-auto container">
            <div className="lg:mx-6-4 xl:mx-59 2xl:mx-1-09 flex flex-row flex-wrap justify-center md:justify-between">
              <div className="text-center w-full ms:w-2/5 lg:w-3/12 flex flex-row justify-center">
                <div className="mx-auto md:mx-0 px-12 lg:px-60 relative text-center font-poppings-bold text-term-primary award-winner-img">
                  <h3 className="text-rp1 lg:text-h3 ">
                    WINNER
                  </h3>
                  <h5 className="text-p4 lg:text-h5">
                    Top B2B Company<br></br> Award
                  </h5>
                  <h3 className="text-rp1 lg:text-h3">
                    2019
                  </h3>
                </div>
              </div>
              <div className="pt-5 ms:pt-0 w-full ms:w-3/5 lg:w-7/10">
                <MarkDown className="pr-0-2 text-p4 lg:text-p2 font-worksans-normal text-term-primary text-center ms:text-left" children={info.frontmatter.description} allowDangerousHtml />
              </div>
            </div>
          </div>
        </section>

        {/* achivement section */}
        <section className="mt-50 lg:mt-20 pb-70">
          <div className="container mx-auto">
            <div className="lg:px-1-1 mx-auto flex flex-wrap justify-between count-contain lg:w-4/5 xl:w-2/3">
              <div className="pb-4-1 sm:pb-6 lg:pb-41 text-left sm:text-center mx-auto">
                <h3 className="pb-1-2 lg:pb-2-3 font-poppings-bold text-rp1 lg:text-h3 text-term-primary">
                  Achievements
                </h3>
                <MarkDown className="md:px-10 lg:px-5 text-p4 lg:text-p2 font-worksans-normal text-term-primary count-text" children={achievement.frontmatter.achievements.description} allowDangerousHtml />
              </div>
              <Counter counterValue={achievement.frontmatter.achievements.values.group} />
            </div>
          </div>
        </section>
        <LiveChat />
      </Layout>
    </>
  );
}

export default Award;

export const pageQuery = graphql`
  query {
    awardData: markdownRemark(fileAbsolutePath: {regex: "/award.md/"}) {
      frontmatter {
        title
        sub_title
        banner {
          content
          alt
          image {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
              )
            }
          }
        }
        awards_sec {
          description
          title
          award_slides {
            slides {
              alt
              name
              image {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: BLURRED
                  )
                }
              }
            }
          }
        }
        description
        achievements {
          values{
            group {
              count
              title
              svg {
                publicURL
              }
            }
          }
          description
        }
      }
    }
    achievement: markdownRemark(fileAbsolutePath: {regex: "/achievements-count-on-us.md/"}) {
      frontmatter {
        achievements {
          description
          values {
            group {
              count
              title
              svg {
                publicURL
              }
            }
          }
        }
      }
    }
    awardpageData: allMarkdownRemark(
      filter: { fileAbsolutePath: {regex: "/award.md/"} }
    ) {
      edges {
        node {
          frontmatter {
            seo_metadata {
              seo_title
              seo_keyword
              seo_description
              image_format
              feature_image_height
              img_width
              facebook_url
              twitter_page_username
              twitter_image_alt_tag
              twitter_estimated_reading_lable
              twitter_estimated_reading_time
              page_publish_date
              page_last_modified_time
              seo_Image {
                childImageSharp {
                  gatsbyImageData(layout: FIXED, width: 1200)
                }
              }
              seo_Single_Image
            }
            Schema {
              sitelinks_schema
              breadcrumb_schema
              logo_schema
              organization_schema
              aggregate_rating_schema
              local_business_schema
              site_navigation_schema
              speakable_specification_schema
            }
            Hreflang {
              hreflang_1
              hreflang_2
              hreflang_3
              hreflang_4
            }
          }
        }
      }
    }
  }
`;