import React from "react"
import { getImage, GatsbyImage } from "gatsby-plugin-image"
import Flickity from 'react-flickity-component'
import 'flickity/dist/flickity.min.css';


const AwardSlider = (props) => {
    const awardOptions = {
        contain: true,
        wrapAround: true,
        initialIndex: 2,
        pageDots: false,
        autoPlay: false
    }
    const sliders = props.awardSlides
    return (
        <div className="a-slider a-award-viewport">
            <Flickity
                className={'award-carousel'}
                elementType={'div'}
                options={awardOptions} >
                {sliders.map((slide, index) => (
                    <div key={index} className="a-award-cell">
                        <div className="a-award-cell-inn">
                            <div className="a-award-cell-img">
                                <GatsbyImage image={getImage(slide.image)} alt={`${slide.alt} | Decodeup Awards`} />
                            </div>
                            <div className="a-award-cell-ttl">
                                <p className="px-61 text-center font-poppings-bold text-cyan">
                                    {slide.name}
                                </p>
                            </div>
                        </div>
                    </div>
                ))}
            </Flickity>
        </div>
    )
}

export default AwardSlider;